import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Input from './form/Input';
import RangeInput from './form/RangeInput';
import TextArea from './form/TextArea';
import Checkbox from './form/Checkbox';
import Swal from 'sweetalert2';
import {
  ConvertNumberToPersionUnitFloat,
  ConvertNumberToEnglishUnitFloat,
} from './Utils';

const EditProduct = () => {
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState([]);
  const { goldUnit } = useOutletContext();
  const [weightErr, setWeightErr] = useState('');
  const [image, setImage] = useState(null);
  const [imageEdited, setImageEdited] = useState(false);

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const [product, setProduct] = useState({
    productId: 0,
    productName: '',
    productWeight: '',
    productPrice: '',
    productShortDescription: '',
    productDescription: '',
    productImagePath: '',
    productDiscount: '',
    categories: [],
    categories_array: [Array(13).fill(false)],
    discount: false,
  });

  // get id from the URL
  let { id } = useParams();
  if (id === undefined) {
    id = 0;
  }

  useEffect(() => {
    if (id === 0) {
      setWeightErr('لطفا وزن محصول را وارد کنید');
      // adding a product
      setProduct({
        productId: 0,
        productName: '',
        productWeight: '',
        productPrice: '',
        productShortDescription: '',
        productDescription: '',
        productImagePath: '',
        productDiscount: '',
        categories: [],
        categories_array: [Array(13).fill(false)],
        discount: false,
      });

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      const requestOptions = {
        method: 'GET',
        headers: headers,
      };

      fetch(`${process.env.REACT_APP_BACKEND}/categories`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          const checks = [];

          data.forEach((c) => {
            checks.push({ id: c.id, checked: false, type: c.type });
          });

          setProduct((p) => ({
            ...p,
            categories: checks,
            categories_array: [],
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      // editing an existing product
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      const requestOptions = {
        method: 'GET',
        headers: headers,
      };

      fetch(
        `${process.env.REACT_APP_BACKEND}/admin/products/${id}`,
        requestOptions
      )
        .then((response) => {
          if (response.status !== 200) {
            setError('Invalid response code: ' + response.status);
          }
          return response.json();
        })
        .then((data) => {
          const checks = [];

          if (data.product.categories_array) {
            data.categories.forEach((c) => {
              if (data.product.categories_array.indexOf(c.id) !== -1) {
                checks.push({ id: c.id, checked: true, type: c.type });
              } else {
                checks.push({ id: c.id, checked: false, type: c.type });
              }
            });
          }

          // set state
          setProduct({
            ...data.product,
            categories: checks,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const fetchingData = async (link) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let requestOptions = {
      method: 'GET',
    };
    const projectsData = await fetch(
      `${process.env.REACT_APP_BACKEND}/${link}`,
      requestOptions
    );
    const name = await projectsData.json();
    return name;
  };

  const uploadImage = (event) => {
    event.preventDefault();
    let photo = event.target.files[0];
    setImage(photo);
    if (product.productImagePath != '') {
      setImageEdited(true);
    } else {
      setProduct({
        ...product,
        productImagePath: photo.name,
      });
    }
  };

  const handleRange = (name) => (event) => {
    let value = event.target.value;
    if (name === 'productWeight') {
      setProduct({
        ...product,
        [name]: value,
        productPrice: String(
          parseFloat(ConvertNumberToEnglishUnitFloat(value)) * goldUnit
        ),
        productDiscount: String(
          parseFloat(ConvertNumberToEnglishUnitFloat(value)) * goldUnit
        ),
      });
    } else {
      setProduct({
        ...product,
        [name]: value,
      });
    }
  };

  const discountCheck = () => {
    setProduct({
      ...product,
      discount: !product.discount,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let errors = [];
    let required = [
      { field: product.productName, name: 'productName' },
      { field: product.productWeight, name: 'productWeight' },
      {
        field: product.productShortDescription,
        name: 'productShortDescription',
      },
      { field: product.productDescription, name: 'productDescription' },
      { field: product.productImagePath, name: 'productImagePath' },
      { field: product.productDiscount, name: 'productDiscount' },
      { field: product.discount, name: 'discount' },
    ];

    required.forEach(function (obj) {
      if (obj.field === '') {
        errors.push(obj.name);
      }
    });

    console.log(product);

    if (product.categories_array.length === 0) {
      Swal.fire({
        title: 'Error!',
        text: 'You must choose at least one category!',
        icon: 'error',
        confirmButtonText: 'OK',
      });
      errors.push('categories');
    }

    setErrors(errors);

    if (errors.length > 0) {
      return false;
    }

    // passed validation, so save changes
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    let imageName;

    //adding a new product
    let method = '';
    let requestBody = product;

    if (product.productId > 0) {
      method = 'PATCH';
    } else {
      method = 'PUT';
      imageName = await fetchingData('admin/images');
      const parts = product.productImagePath.split('.');
      const extension = parts[parts.length - 1];
      requestBody.productImagePath = imageName + '.' + extension;
    }

    let formData = new FormData();
    formData.append('photo', image);
    formData.append('imageName', requestBody.productImagePath);

    requestBody.discount = product.discount ? 1 : 0;

    let requestOptions = {
      body: JSON.stringify(requestBody),
      method: method,
      headers: headers,
    };

    console.log(requestBody);

    fetch(
      `${process.env.REACT_APP_BACKEND}/admin/products/${product.productId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setProduct({
          productId: parseInt(data.data),
          ...product,
        });
        if (data.error) {
          console.log(data.error);
        }
        return data.data;
      })
      .then(() => {
        if (method === 'PUT' || imageEdited) {
          let requestOptionsImage = {
            body: formData,
            method: 'POST',
          };
          fetch(
            `${process.env.REACT_APP_BACKEND}/admin/products/images/${product.productId}`,
            requestOptionsImage
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                console.log(data.error);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  };

  const handleCheck = (event, position) => {
    let tmpArr = product.categories;
    console.log(position);
    tmpArr[position].checked = !tmpArr[position].checked;

    let tmpIDs = product.categories_array;
    if (!event.target.checked) {
      tmpIDs.splice(tmpIDs.indexOf(parseInt(event.target.value, 10)), 1);
    } else {
      tmpIDs.push(parseInt(event.target.value, 10));
    }

    setProduct({
      ...product,
      categories_array: tmpIDs,
    });
  };

  const handleChange = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    if (name === 'productWeight') {
      console.log('in productWeight handler', name);
      setProduct({
        ...product,
        [name]: ConvertNumberToEnglishUnitFloat(value),
        productPrice: String(
          parseFloat(ConvertNumberToEnglishUnitFloat(value)) * goldUnit
        ),
        productDiscount: String(
          parseFloat(ConvertNumberToEnglishUnitFloat(value)) * goldUnit
        ),
      });
    } else if (name === 'productPrice') {
      console.log(ConvertNumberToEnglishUnitFloat(value));
      setProduct({
        ...product,
        [name]: ConvertNumberToEnglishUnitFloat(value),
      });
    } else if (name === 'productDiscount') {
      console.log(ConvertNumberToEnglishUnitFloat(value));
      setProduct({
        ...product,
        [name]: ConvertNumberToEnglishUnitFloat(value),
      });
    } else {
      setProduct({
        ...product,
        [name]: value,
      });
    }
  };

  if (error !== null) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h2 className="iranfontbold d-flex">اضافه/ویرایش محصولات:</h2>
            <hr />

            <form className="iranfont" onSubmit={handleSubmit}>
              <input
                type="hidden"
                name="id"
                value={product.productId}
                id="id"
              ></input>

              <Input
                title={'نام محصول'}
                className={'form-control'}
                type={'text'}
                name={'productName'}
                value={product.productName}
                onChange={handleChange('productName')}
                errorDiv={hasError('title') ? 'text-danger' : 'd-none'}
                errorMsg={'لطفا نام محصول را وارد کنید'}
              />

              <RangeInput
                title={'وزن محصول '}
                className={'form-control'}
                styleDirection="ltr"
                type={'text'}
                name={'productWeight'}
                value={ConvertNumberToEnglishUnitFloat(product.productWeight)}
                onChange={handleChange('productWeight')}
                handleRange={handleRange('productWeight')}
                errorDiv={hasError('productWeight') ? 'text-danger' : 'd-none'}
                errorMsg={weightErr}
                min={0}
                max={20}
                step={0.1}
              />

              <Input
                title={'قیمت محصول (تومان)'}
                className={'form-control'}
                styleDirection="ltr"
                type={'text'}
                name={'productPrice'}
                value={String(parseFloat(product.productWeight) * goldUnit)}
                valuePersian={ConvertNumberToPersionUnitFloat(
                  product.productPrice
                )}
                persian={true}
                onChange={handleChange('productPrice')}
              />

              <Checkbox
                title={'دارای تخفیف؟'}
                name={'discount'}
                value={product.discount}
                onChange={discountCheck}
              />

              {(product.discount === true || product.discount === 1) && (
                <>
                  <br />
                  <RangeInput
                    title={'قیمت با تخفیف'}
                    styleDirection={'ltr'}
                    className={'form-control'}
                    type={'text'}
                    name={'productDiscount'}
                    value={product.productDiscount}
                    onChange={handleChange('productDiscount')}
                    handleRange={handleRange('productDiscount')}
                    valuePersian={ConvertNumberToPersionUnitFloat(
                      product.productDiscount
                    )}
                    persian={true}
                    min={
                      parseInt(product.productPrice) -
                      parseInt(product.productPrice * 0.04)
                    }
                    max={
                      parseInt(product.productPrice) +
                      parseInt(product.productPrice * 0.04)
                    }
                    step={1000}
                  />
                </>
              )}

              <br />

              <Input
                title={'تصویر'}
                className={'form-control'}
                type={'file'}
                name={'productImagePath'}
                onChange={uploadImage}
                errorDiv={
                  hasError('productImagePath') ? 'text-danger' : 'd-none'
                }
                errorMsg={'لطفا یک تصویر برای محصول انتخاب کنید'}
              />
              {product.productImagePath != '' && (
                <img
                  src={`${process.env.REACT_APP_BACKEND}/${product.productImagePath}`}
                  alt="Image"
                  className="img-fluid"
                  style={{ objectFit: 'cover', height: '200px' }}
                />
              )}

              <Input
                title={'توضیح کوتاه'}
                className={'form-control'}
                type={'text'}
                name={'productShortDescription'}
                value={product.productShortDescription}
                onChange={handleChange('productShortDescription')}
                errorDiv={
                  hasError('productShortDescription') ? 'text-danger' : 'd-none'
                }
                errorMsg={'لطفا توضیح کوتاه برای محصول بنویسید'}
              />

              <TextArea
                title="توضیح کامل"
                name={'productDescription'}
                value={product.productDescription}
                rows={'3'}
                onChange={handleChange('productDescription')}
                errorMsg={'لطفا توضیح کامل را وارد کنید'}
                errorDiv={
                  hasError('productDescription') ? 'text-danger' : 'd-none'
                }
              />

              <hr />

              <label className="mb-1 d-flex form-check-label iranfontbold">
                دسته&zwnj;بندی
              </label>

              <div className="mb-2 mt-1 d-flex flex-wrap iranfont">
                {Array.from(product.categories).map((c, index) => (
                  <Checkbox
                    title={c.type}
                    name={'category'}
                    key={index}
                    id={'category-' + index}
                    onChange={(event) => handleCheck(event, index)}
                    value={c.id}
                    checked={product.categories[index].checked}
                  />
                ))}
              </div>

              <hr />
              <input
                type="submit"
                className="btn btn-warning btn-block"
                value="ذخیره"
              />
            </form>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    );
  }
};

export default EditProduct;
