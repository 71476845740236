import './Footer.css';
const Footer = () => {
  return (
    <footer>
      <div className="container text-right">
        <div className="row iranfont">
          <div className="col-lg-3">
            <div className="first-item">
              <div className="logo">
                <img src="/images/cac.png" />
              </div>
              <ul>
                <li>
                  <a href="#" className="navfont">
                    بروجرد، بازار زرگرها، روبروی بازار حسامی، پلاک ۷۹
                  </a>
                </li>
                <li>
                  <a href="#">jeyranjewelry@gmail.com</a>
                </li>
                <li>
                  <a href="tel://09332628002">09332628002</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 iranfontregular">
            <h4>خرید و محصولات</h4>
            <ul>
              <li>
                <a href="#">سرویس عروس</a>
              </li>
              <li>
                <a href="#">انگشتر</a>
              </li>
              <li>
                <a href="#">گردنبند</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 iranfontregular">
            <h4>لینک های سودمند</h4>
            <ul>
              <li>
                <a href="#">خانه</a>
              </li>
              <li>
                <a href="#">ارتباط با ما</a>
              </li>
              <li>
                <a href="#">کمک</a>
              </li>
              <li>
                <a href="#">درباره ما</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 iranfontregular">
            <h4>راهنمای خرید</h4>
            <ul>
              <li>
                <a href="#">نحوه انتخاب و سفارش</a>
              </li>
              <li>
                <a href="#">فرآیند ارسال</a>
              </li>
              <li>
                <a href="#">پرداخت</a>
              </li>
              <li>
                <a href="#">قوانین</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <div className="under-footer">
              <p>
                <span className="iranfont">
                  تمام حقوق اين وب‌سايت برای طلا و جواهر جیران است.
                </span>
                <br />
                Design:{' '}
                <a
                  href="f.moradi.d@gmail.com"
                  target="_parent"
                  title="free css templates"
                >
                  f.moradi.d@gmail.com
                </a>
              </p>
              <ul>
                {/* <li>
                  <a href="#">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li> */}
                <li>
                  <a href="https://instagram.com/jeyran.jewerly">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

{
  /* <footer>
     <div className="footer">
       <div className="container">
         <div className="row">
           <div className="col-md-6">
             <div className="call_now2">
               <h3>Free Multipurpose Responsive </h3>
               <span>Landing Page 2019</span>
             </div>
           </div>
           <div className="col-md-6">
             <div className="call_now">
               <h3>Call Now</h3>
               <span>(+1)1234567890</span>
             </div>
           </div>
         </div>
       </div>
       <div className="copyright">
         <div className="container">
           <p>
             © 2019 All Rights Reserved.{' '}
             <a href="https://html.design/">Free html Templates</a>
           </p>
         </div>
       </div>
     </div>
   </footer> */
}
