import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './ProductCarousel.css';
import ProductCarouselRow from './ProductCarouselRow';

const ProductCarousel = (props) => {
  const [products, setProducts] = useState([]);
  const [pages, setPages] = useState(0);
  const [id, setId] = useState('');
  const [carouselItemsCount, setCarouselItemsCount] = useState(4);

  useEffect(() => {
    // necessary to forse useEffect to wait until the props is loaded
    if (props.categoryId && props.id) {
      setId(props.id);
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');

      let requestOptions = {
        method: 'GET',
        headers: headers,
      };

      fetch(
        `${process.env.REACT_APP_BACKEND}/products/categories/${props.categoryId}?limit=12`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          data.map((i) => {
            i.discount = i.discount ? true : false;
            i.productImagePath = `${process.env.REACT_APP_BACKEND}/${i.productImagePath}`;
          });
          setProducts(data);
          setPages(parseInt(data.length / (carouselItemsCount + 1)));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.categoryId, props.id, carouselItemsCount]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to update the state with the current width of the window
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  // Effect to update the state when the window is resized
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', updateWindowWidth);

    // Remove event listener when component is unmounted
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 500) {
      setCarouselItemsCount(1);
    } else if (windowWidth > 500 && windowWidth < 650) {
      setCarouselItemsCount(2);
    } else {
      setCarouselItemsCount(4);
    }
    setPages(parseInt(products.length / (carouselItemsCount + 1)));
  }, [windowWidth]);

  return (
    <div className="row">
      <div className="col-md-12 col-sm-12">
        <hr />
        <h2 className="text-center iranfont">
          <Link
            className="navfont"
            to={{
              pathname: `/products`,
              search: `?category=${props.categoryName}`,
            }}
          >
            {props.titleName}
          </Link>
        </h2>
        <div
          id={'myCarousel' + id}
          className="carousel slide"
          data-ride="carousel"
          data-interval="0"
          style={{ direction: 'rtl' }}
        >
          <ol className="carousel-indicators" style={{ direction: 'rtl' }}>
            <li
              data-target={'#myCarousel' + id}
              data-slide-to="0"
              className="active"
            ></li>
            {Array(pages)
              .fill(1)
              .map((i, j) => {
                return (
                  <li
                    data-target={'#myCarousel' + { id }}
                    data-slide-to={(j + 1).toString()}
                    key={'myCarousel-key-' + { i }}
                  ></li>
                );
              })}
            {/* <li data-target={'#myCarousel' + id} data-slide-to="1"></li> */}
          </ol>
          <div className="carousel-inner">
            {Array(pages + 1)
              .fill(carouselItemsCount)
              .map((i, j) => {
                return (
                  <ProductCarouselRow
                    key={`myCarousel-row-key-${j}`}
                    Products={products.slice(
                      0 + carouselItemsCount * j,
                      carouselItemsCount + carouselItemsCount * j
                    )}
                    Active={j === 0 ? true : false}
                    id={id}
                  />
                );
              })}
          </div>

          <a
            className="carousel-control-prev"
            href={'#myCarousel' + id}
            data-slide="next"
          >
            <i className="fa fa-angle-left"></i>
          </a>
          <a
            className="carousel-control-next"
            href={'#myCarousel' + id}
            data-slide="prev"
          >
            <i className="fa fa-angle-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductCarousel;
