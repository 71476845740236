import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Product.css';
import { Context } from '../Context';
import { ConvertNumberToPersion, ConvertNumberToPersionUnit } from './Utils.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Product = (props) => {
  let { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const { items, setItems } = useContext(Context);
  const [qty, setQty] = useState(0);
  const [product, setProduct] = useState({});

  const clickHandler = () => {
    setItems((prevcount) => prevcount + 1);
  };

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let requestOptions = {
      method: 'GET',
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/products/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        data.discount = data.discount ? true : false;
        data.productImagePath = `${process.env.REACT_APP_BACKEND}/${data.productImagePath}`;
        setProduct(data);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      {loaded ? (
        <div className="row">
          <div className="container">
            <div className="row pt-2 m-0">
              <div className="col-md-6 text-center">
                <LazyLoadImage
                  src={product.productImagePath}
                  alt="Image"
                  className="img-fluid"
                  style={{ objectFit: 'cover', width: '90%', height: '500px' }}
                  effect="blur"
                />
                {/* <img
                  src={product.productImagePath}
                  alt="Image"
                  className="img-fluid"
                  style={{ objectFit: 'cover', width: '90%', height: '500px' }}
                /> */}
              </div>
              <div className="col-md-6 mt-3 iranfontbold text-right">
                <h4>{product.productName}</h4>
                <hr />
                <p className="iranfontregular text-justify">
                  {product.productDescription}
                </p>
                <hr />
                <p className="mb-4">
                  <span>وزن: </span>
                  <span className="iranfontregular text-muted">
                    {ConvertNumberToPersionUnit(product.productWeight) + ' گرم'}
                  </span>
                </p>
                <p className="iranfontbold">
                  {ConvertNumberToPersion(product.productPrice)}
                </p>

                <div
                  className="mb-5"
                  style={{ float: 'right', opacity: '0.8' }}
                >
                  <div className="border border-secondary rounded">
                    <a
                      href="#"
                      className={
                        'btn iranfont ' + (qty > props.Max ? 'disabled' : '')
                      }
                    >
                      <span className="fa fa-plus text-danger"></span>
                    </a>
                    <span className="py-2 px-3 text-danger">{qty}</span>
                    <a
                      href="#"
                      className={
                        'btn iranfont ' + (qty === 0 ? 'disabled' : '')
                      }
                    >
                      <span className="fa fa-minus text-danger"></span>
                    </a>
                  </div>
                </div>
                <p>
                  <a
                    href="#!"
                    className="btn btn-danger mr-5"
                    style={{ opacity: '0.8' }}
                    onClick={clickHandler}
                  >
                    اضافه به سبد خرید
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          <img src="/images/img.png" className="img" alt="#" />
        </div>
      )}
    </>
  );
};
export default Product;
