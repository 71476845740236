import ProductItem from './ProductItem';
import { ConvertNumberToPersion, ConvertNumberToPersionUnit } from './Utils.js';

const ProductCarouselRow = (props) => {
  return (
    <>
      {props.Active === true ? (
        <div className="carousel-item active">
          <div className="row">
            {props.Products.map((p) => (
              <ProductItem
                ProductName={p.productName}
                ProductPrice={ConvertNumberToPersion(p.productPrice)}
                ProductDiscount={ConvertNumberToPersion(p.productDiscount)}
                Discount={p.discount}
                Image={p.productImagePath}
                key={p.productId + props.id}
                ProductId={p.productId}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="carousel-item">
          <div className="row">
            {props.Products.map((p) => (
              <ProductItem
                ProductName={p.productName}
                ProductPrice={ConvertNumberToPersion(p.productPrice)}
                ProductDiscount={ConvertNumberToPersion(p.productDiscount)}
                Discount={p.discount}
                Image={p.productImagePath}
                key={p.productId + props.id}
                ProductId={p.productId}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ProductCarouselRow;
