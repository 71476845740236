import { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Input from './form/Input';
import { ConvertNumberToEnglishUnitFloat } from './Utils';

const EditCategory = () => {
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState([]);
  const [image, setImage] = useState(null);

  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const [category, setCategory] = useState({
    id: 0,
    type: '',
  });

  // get id from the URL
  let { id } = useParams();
  if (id === undefined) {
    id = 0;
  }

  useEffect(() => {
    if (id === 0) {
      // adding a category
      setCategory({
        id: 0,
        type: '',
      });
    }
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let errors = [];
    let required = [{ field: category.type, name: 'type' }];

    required.forEach(function (obj) {
      if (obj.field === '') {
        errors.push(obj.name);
      }
    });

    setErrors(errors);

    if (errors.length > 0) {
      return false;
    }

    // passed validation, so save changes
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    //adding a new product
    let method = 'PUT';

    let requestBody = category;

    let requestOptions = {
      body: JSON.stringify(requestBody),
      method: method,
      headers: headers,
    };

    fetch(
      `${process.env.REACT_APP_BACKEND}/admin/categories/${category.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          console.log(data.error);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setCategory({
      ...category,
      [name]: value,
    });
  };

  if (error !== null) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <h2 className="iranfontbold d-flex">
              اضافه کردن دسته&zwnj;بندی جدید:
            </h2>
            <hr />

            <form className="iranfont" onSubmit={handleSubmit}>
              <input
                type="hidden"
                name="id"
                value={category.id}
                id="id"
              ></input>

              <Input
                title={'نام دسته'}
                className={'form-control'}
                type={'text'}
                name={'type'}
                value={category.type}
                onChange={handleChange('type')}
                errorDiv={hasError('title') ? 'text-danger' : 'd-none'}
                errorMsg={'لطفا نام دسته را وارد کنید'}
              />

              {/* <Input
                title={'تصویر'}
                className={'form-control'}
                type={'file'}
                name={'productImagePath'}
                onChange={uploadImage}
                errorDiv={
                  hasError('productImagePath') ? 'text-danger' : 'd-none'
                }
                errorMsg={'لطفا یک تصویر برای محصول انتخاب کنید'}
              /> */}

              <hr />
              <input
                type="submit"
                className="btn btn-warning btn-block"
                value="ذخیره"
              />
            </form>
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    );
  }
};

export default EditCategory;
