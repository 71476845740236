import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/categories`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.message);
        } else {
          setCategories(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });

  if (error !== null) {
    return <div>Error: {error.message}</div>;
  } else {
    return (
      <div>
        <h2>Categories</h2>
        <hr />

        <div className="list-group">
          {categories.map((c) => (
            <Link
              key={c.id}
              className="list-group-item list-group-item-action"
              to={{ pathname: `/products`, search: `?category=${c.type}` }}
            >
              {c.type}
            </Link>
          ))}
        </div>
      </div>
    );
  }
};

export default Categories;
