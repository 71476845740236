import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Input from './form/Input';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { setJwtToken } = useOutletContext();
  const { setAlertClassName } = useOutletContext();
  const { setAlertMessage } = useOutletContext();

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (email === '111') {
      setJwtToken('abc');
      setAlertClassName('d-none');
      setAlertMessage('');
      navigate('/');
    } else {
      setAlertClassName('alert-danger');
      setAlertMessage('Invalid credentials');
    }

    // build the request payload
    // let payload = {
    //     email: email,
    //     password: password,
    // }

    // const requestOptions = {
    //     method: "POST",
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     credentials: 'include',
    //     body: JSON.stringify(payload),
    // }

    // fetch(`${process.env.REACT_APP_BACKEND}/authenticate`, requestOptions)
    //     .then((response) => response.json())
    //     .then((data) => {
    //         if (data.error) {
    //             setAlertClassName("alert-danger");
    //             setAlertMessage(data.message);
    //         } else {
    //             setJwtToken(data.access_token);
    //             setAlertClassName("d-none");
    //             setAlertMessage("");
    //             toggleRefresh(true);
    //             navigate("/");
    //         }
    //     })
    //     .catch(error => {
    //         setAlertClassName("alert-danger");
    //         setAlertMessage(error);
    //     })
  };

  return (
    <div className="row iranfont">
      <div className="col-md-4"></div>
      <div className="col-md-3">
        <h2 className="d-flex">ورود</h2>
        <form onSubmit={handleSubmit} className="mt-5">
          <Input
            title="نام کاربری ( شماره تلفن)"
            type="tel"
            styleDirection="ltr"
            className="form-control englishfont text-left"
            name="phone"
            autoComplete="phone-new"
            onChange={(event) => setEmail(event.target.value)}
          />

          <Input
            title="کلمه عبور"
            type="password"
            styleDirection="ltr"
            className="form-control englishfont text-left"
            name="password"
            autoComplete="password-new"
            onChange={(event) => setPassword(event.target.value)}
          />

          <hr />

          <input
            type="submit"
            className="btn btn-warning btn-block"
            value="ورود"
          />
        </form>
      </div>
      <div className="col-md-5"></div>
    </div>
  );
};

export default Login;
