import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Header from './Header';
import ProductCarousel from './ProductCarousel';
import './Home.css';
const Home = () => {
  const [categories, setCategories] = useState([]);
  const [categoryIndex, SetCategoryIndex] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/categories`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let tempObj = {};
        console.log(process.env.REACT_APP_BACKEND);
        console.log(data);
        data.map((o) => (tempObj[o.type] = o.id));
        SetCategoryIndex(tempObj);
        setCategories(data);
        setLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Header />
      {loaded === false ? (
        <div style={{ height: '100%' }}>
          <div className="main-layout"></div>
          {/* <div style={{ height: '100%' }}>
            <img src="images/img.png" className="img" alt="#" />
          </div> */}
        </div>
      ) : (
        <>
          <div className="main-layout">
            <div id="jewellery" style={{ direction: 'ltr' }}>
              <div className="container-fluid">
                <ProductCarousel
                  titleName="محبوبترین محصولات"
                  categoryId={categoryIndex['محبوبترین']}
                  categoryName="محبوبترین"
                  id="3"
                />
                <ProductCarousel
                  titleName="جدیدترین"
                  categoryId={categoryIndex['جدیدترین']}
                  categoryName="جدیدترین"
                  id="4"
                />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="best_main">
                      <div className="row d_flex border-sm">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                          <div className="best_text ">
                            <h3
                              className="navfont text-center"
                              style={{ marginLeft: '30%' }}
                            >
                              طراحی به روزترین انگشترها و قبول سفارشات برای
                              طراحی های خاص
                            </h3>
                            <Link
                              className="navfont"
                              to={{
                                pathname: `/products`,
                                search: `?category=انگشتر`,
                              }}
                            >
                              مشاهده محصولات
                            </Link>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12">
                          <div className="best_img d_none">
                            <figure>
                              <img src="images/beimg.png" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <ProductCarousel
                  titleName="سرویس عروس"
                  categoryId={categoryIndex['سرویس عروس']}
                  categoryName="سرویس عروس"
                  id="1"
                />
              </div>
              {/* <div className="container mb-5">
                <div className="row mb-5">
                  <div className="col-md-12">
                    <div className="best_main">
                      <div className="row d_flex border-sm">
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6">
                          <div className="best_img d_none">
                            <h4 className="navfont">سرویس عروس</h4>
                            <figure>
                              <img src="images/wedding.jpg" />
                            </figure>
                          </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6 mt-5 pt-5">
                          <div className="best_text flot_left">
                            <Link
                              className="navfont"
                              to={{
                                pathname: `/products`,
                                search: `?category=سرویس عروس`,
                              }}
                            >
                              مشاهده محصولات
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="best_main pa_bot">
                      <div className="row d_flex border-sm">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6">
                          <div className="best_text">
                            <Link
                              className="navfont"
                              to={{
                                pathname: `/products`,
                                search: `?category=گردنبند`,
                              }}
                            >
                              مشاهده محصولات
                            </Link>
                          </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6">
                          <div className="best_img d_none">
                            <h4 className="navfont">گردنبند</h4>
                            <figure>
                              <img src="images/beimg3.png" />
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="container-fluid">
            <ProductCarousel
              categoryId={categoryIndex['گردنبند']}
              titleName="گردنبندها"
              categoryName="گردنبند"
              id="2"
            />
          </div> */}
        </>
      )}
    </>
  );
};

export default Home;
