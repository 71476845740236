import { useState, useEffect } from 'react';
import './Products.css';

import { ConvertNumberToPersion, ConvertNumberToPersionUnit } from './Utils.js';
import { Link, useLocation } from 'react-router-dom';
import RepresentedProducts from './RepresentedProducts';
const Products = () => {
  const [loaded, setLoaded] = useState(false);
  const [SliderValue, setSliderValue] = useState(50);
  const [products, setProducts] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  const [categoryIndex, SetCategoryIndex] = useState({});
  const location = useLocation();
  const [categoriesCount, setCategoriesCount] = useState({});
  const categoryName = new URLSearchParams(location.search).get('category');
  const [filterReady, setFilterReady] = useState(false);
  const [sortOption, setSortOption] = useState(0);

  const getCategories = async () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let requestOptions = {
      method: 'GET',
      headers: headers,
    };
    await fetch(`${process.env.REACT_APP_BACKEND}/categories`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let tempObj = {};
        data.map((o) => (tempObj[o.type] = o.id));
        SetCategoryIndex(tempObj);
        if (categoryName !== null) {
          loadFilteredData(categoryName, tempObj);
        }
      });
  };

  const getCounts = async () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let requestOptions = {
      method: 'GET',
      headers: headers,
    };
    await fetch(
      `${process.env.REACT_APP_BACKEND}/products/counts`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let tempObj = {};
        data.map((c) => {
          tempObj[c.id] = c.count;
        });
        setCategoriesCount({
          ...tempObj,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSort = (event) => {
    switch (event.target.value) {
      case 'cheapest':
        setSortOption(2);
        break;
      case 'most-expensive':
        setSortOption(1);
        break;
      case 'most-favourite':
        setSortOption(0);
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getCounts();
        await getCategories();

        setPageTitle('همه محصولات');
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        let requestOptions = {
          method: 'GET',
          headers: headers,
        };

        if (categoryName === null) {
          fetch(`${process.env.REACT_APP_BACKEND}/products`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              data.map((i) => {
                i.discount = i.discount ? true : false;
                i.productImagePath = `${process.env.REACT_APP_BACKEND}/${i.productImagePath}`;
              });
              setProducts(data);
              setLoaded(true);
              setFilterReady(true);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [location]);

  // getting the counts of prodects useEffect
  // useEffect(() => {
  //   console.log('reloaded by', categoryName);
  //   handleFilter(categoryName)();
  // }, [location]);

  const loadFilteredData = (category, categoryIndex) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let requestOptions = {
      method: 'GET',
      headers: headers,
    };

    fetch(
      `${process.env.REACT_APP_BACKEND}/products/categories/${categoryIndex[category]}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setPageTitle(category);
        data.map((i) => {
          i.discount = i.discount ? true : false;
          i.productImagePath = `${process.env.REACT_APP_BACKEND}/${i.productImagePath}`;
        });
        setProducts(data);
        setLoaded(true);
        setFilterReady(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {loaded === true ? (
        <div className="container site-section">
          <div className="row mb-5">
            <RepresentedProducts
              pageTitle={pageTitle}
              products={products}
              sort={sortOption}
            />
            <div className="col-md-3 order-1 mb-5 mb-md-0">
              {filterReady === true ? (
                <div className="border p-4 rounded mb-4">
                  <h3 className="mb-3 h6 text-uppercase text-black d-flex iranfontbold">
                    فیلترها
                  </h3>
                  <ul className="list-unstyled mb-0 iranfontregular">
                    <li className="mb-1">
                      <Link
                        to={{
                          pathname: `/products`,
                          search: `?category=گردنبند`,
                        }}
                        className="d-flex"
                      >
                        <span>گردنبند</span>{' '}
                        <span className="text-black ml-auto mr-2">
                          (
                          {ConvertNumberToPersionUnit(
                            categoriesCount[categoryIndex['گردنبند']].toString()
                          )}
                          )
                        </span>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        to={{
                          pathname: `/products`,
                          search: `?category=انگشتر`,
                        }}
                        className="d-flex"
                      >
                        <span>انگشتر</span>
                        <span className="text-black ml-auto mr-2">
                          (
                          {ConvertNumberToPersionUnit(
                            categoriesCount[categoryIndex['انگشتر']].toString()
                          )}
                          )
                        </span>
                      </Link>
                    </li>
                    <li className="mb-1">
                      <Link
                        className="d-flex"
                        to={{
                          pathname: `/products`,
                          search: `?category=سرویس عروس`,
                        }}
                      >
                        <span>سرویس عروس</span>{' '}
                        <span className="text-black ml-auto mr-2">
                          (
                          {ConvertNumberToPersionUnit(
                            categoriesCount[
                              categoryIndex['سرویس عروس']
                            ].toString()
                          )}
                          )
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : (
                <p></p>
              )}

              <div className="border p-4 rounded mb-4">
                <div className="mb-4">
                  <form>
                    <div className="form-group ">
                      <label
                        htmlFor="formControlRange"
                        className="iranfontregular"
                        style={{ float: 'right' }}
                      >
                        حداکثر قیمت
                      </label>
                      <input
                        type="range"
                        min="0"
                        max="50000000"
                        className="form-control-range"
                        id="formControlRange"
                        onInput={function (event) {
                          setSliderValue(event.target.value);
                        }}
                        value={SliderValue}
                      />
                      <span id="rangeval" className="iranfont small">
                        {ConvertNumberToPersion(SliderValue.toString())}
                      </span>
                    </div>
                  </form>
                </div>

                <div className="mb-4">
                  <h3 className="mb-3 h6 text-uppercase text-black d-flex d-block iranfont">
                    مرتب&zwnj;سازی
                  </h3>
                  <div className="form-check d-flex">
                    <input
                      type="radio"
                      id="r_fa"
                      className="mr-2 form-check-input"
                      name="flexRadioDefault"
                      value={'most-favourite'}
                      defaultChecked="checked"
                      onChange={handleSort}
                    />{' '}
                    <label
                      className="small iranfont mr-4 form-check-label"
                      htmlFor="r_fa"
                    >
                      محبوب&zwnj;ترین
                    </label>
                  </div>

                  <div className="form-check d-flex">
                    <input
                      type="radio"
                      id="r_ex"
                      className="mr-2 form-check-input"
                      name="flexRadioDefault"
                      value={'most-expensive'}
                      onChange={handleSort}
                    />{' '}
                    <label
                      className="small iranfont mr-4 form-check-label"
                      htmlFor="r_ex"
                    >
                      گران&zwnj;ترین
                    </label>
                  </div>

                  <div className="form-check d-flex">
                    <input
                      type="radio"
                      id="r_ch"
                      className="mr-2 form-check-input"
                      name="flexRadioDefault"
                      value={'cheapest'}
                      onChange={handleSort}
                    />{' '}
                    <label
                      className="small iranfont mr-4 form-check-label"
                      htmlFor="r_ch"
                    >
                      ارزان&zwnj;ترین
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          <img src="/images/img.png" className="img" alt="#" />
        </div>
      )}
    </>
  );
};

export default Products;
