import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import ErrorPage from './components/ErrorPage';
import Home from './components/Home';
import Products from './components/Products';
import Product from './components/Product';
import Login from './components/Login';
import EditProduct from './components/EditProduct';
import EditCategory from './components/EditCategory';
import ManageCatalogue from './components/ManageCatalogue';
import OneCategory from './components/OneCategory';
import Categories from './components/Categories';
import RepresentedProducts from './components/RepresentedProducts';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/products',
        element: <Products />,
      },
      {
        path: '/products/:id',
        element: <Product />,
      },
      {
        path: '/categories',
        element: <Categories />,
      },
      {
        path: '/categories/:id',
        element: <OneCategory />,
      },
      {
        path: '/admin/products/0',
        element: <EditProduct />,
      },
      {
        path: '/admin/products/:id',
        element: <EditProduct />,
      },
      {
        path: '/admin/categories/0',
        element: <EditCategory />,
      },
      {
        path: '/manage-catalogue',
        element: <ManageCatalogue />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
