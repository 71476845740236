import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductItem = (props) => {
  return (
    <div className={`col`}>
      <Link to={'/products/' + props.ProductId}>
        <div
          className="thumb-wrapper text-center iranfont"
          style={{ direction: 'rtl' }}
        >
          <div className="img-box">
            <LazyLoadImage
              src={props.Image}
              className="object-cover w-full h-full text-center mx-auto"
              alt=""
              effect="blur"
              width={180}
              height={200}
            />
            {/* <img src={props.Image} className="img-fluid" alt="" /> */}
          </div>
          <div className="thumb-content mt-5" style={{ minHeight: '200px' }}>
            <h4>{props.ProductName}</h4>
            <p className="item-price">
              {props.Discount === true ? (
                <>
                  <strike>{props.ProductPrice}</strike>{' '}
                  <span>{props.ProductDiscount}</span>
                </>
              ) : (
                <span>{props.ProductPrice}</span>
              )}
            </p>
            <a href="#" className="btn btn-primary iranfont">
              اضافه به سبد خرید
            </a>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default ProductItem;
