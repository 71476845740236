import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Products.css';
import ProductItemList from './ProductItemList';
import { ConvertNumberToPersion, ConvertNumberToPersionUnit } from './Utils.js';
const OneCategory = () => {
  const [loaded, setLoaded] = useState(false);
  const [SliderValue, setSliderValue] = useState(50);
  const [products, setProducts] = useState([]);
  const [pageTitle, setPageTitle] = useState('');
  let { id } = useParams();

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    let requestOptions = {
      method: 'GET',
      headers: headers,
    };

    fetch(
      `${process.env.REACT_APP_BACKEND}/products/categories/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setPageTitle(id);
        console.log(data);
        data.map((i) => {
          i.discount = i.discount ? true : false;
          i.productImagePath = `${process.env.REACT_APP_BACKEND}/${i.productImagePath}`;
        });
        setProducts(data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoaded(true);
  }, []);
  return (
    <>
      {loaded === true ? (
        <div className="container site-section">
          <div className="row">
            <div className="col-md-12">
              <div className="float-md-right mb-4">
                <h2 className="text-black h5 iranfontbold">{pageTitle}</h2>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-9 order-2">
              <div className="row mb-5">
                {products.map((p) => (
                  <ProductItemList
                    ProductID={p.productId}
                    ProductName={p.productName}
                    ProductWeight={p.productWeight}
                    ProductDescription={p.productShortDescription}
                    ProductPrice={p.productPrice}
                    discount={p.discount}
                    ProductDiscount={p.productDiscount}
                    ProductImagePath={p.productImagePath}
                  />
                ))}
              </div>
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="site-block-27">
                    <ul>
                      <li>
                        <a href="#">&lt;</a>
                      </li>
                      <li className="active">
                        <span>1</span>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 order-1 mb-5 mb-md-0">
              <div className="border p-4 rounded mb-4">
                <h3 className="mb-3 h6 text-uppercase text-black d-flex iranfontbold">
                  فیلترها
                </h3>
                <ul className="list-unstyled mb-0 iranfontregular">
                  <li className="mb-1">
                    <a href="#" className="d-flex">
                      <span>گردنبند</span>{' '}
                      <span className="text-black ml-auto mr-2">
                        ({ConvertNumberToPersionUnit('2220')})
                      </span>
                    </a>
                  </li>
                  <li className="mb-1">
                    <a href="#" className="d-flex">
                      <span>انگشتر</span>{' '}
                      <span className="text-black ml-auto mr-2">
                        ({ConvertNumberToPersionUnit('2220')})
                      </span>
                    </a>
                  </li>
                  <li className="mb-1">
                    <a href="#" className="d-flex">
                      <span>حلقه</span>{' '}
                      <span className="text-black ml-auto mr-2">
                        ({ConvertNumberToPersionUnit('2220')})
                      </span>
                    </a>
                  </li>
                </ul>
              </div>

              <div className="border p-4 rounded mb-4">
                <div className="mb-4">
                  <form>
                    <div className="form-group ">
                      <label
                        htmlFor="formControlRange"
                        className="iranfontregular"
                        style={{ float: 'right' }}
                      >
                        حداکثر قیمت
                      </label>
                      <input
                        type="range"
                        min="0"
                        max="50000000"
                        className="form-control-range"
                        id="formControlRange"
                        onInput={function (event) {
                          setSliderValue(event.target.value);
                        }}
                        value={SliderValue}
                      />
                      <span id="rangeval" className="iranfont small">
                        {ConvertNumberToPersion(SliderValue.toString())}
                      </span>
                    </div>
                  </form>
                </div>

                <div className="mb-4">
                  <h3 className="mb-3 h6 text-uppercase text-black d-flex d-block iranfont">
                    مرتب&zwnj;سازی
                  </h3>
                  <label htmlFor="s_sm" className="d-flex">
                    <input type="checkbox" id="s_sm" className="mr-2" />{' '}
                    <span className="small iranfont mr-2">گران&zwnj;ترین</span>
                  </label>
                  <label htmlFor="s_md" className="d-flex">
                    <input type="checkbox" id="s_md" className="mr-2" />{' '}
                    <span className="small iranfont mr-2">ارزان&zwnj;ترین</span>
                  </label>
                  <label htmlFor="s_lg" className="d-flex">
                    <input type="checkbox" id="s_lg" className="mr-2" />{' '}
                    <span className="small iranfont mr-2">محبوب&zwnj;ترین</span>
                  </label>
                </div>

                {/* <div className="mb-4">
                  <h3 className="mb-3 h6 text-uppercase text-black d-block">
                    Color
                  </h3>
                  <a href="#" className="d-flex color-item align-items-center">
                    <span className="bg-danger color d-inline-block rounded-circle mr-2"></span>{' '}
                    <span className="text-black">Red (2,429)</span>
                  </a>
                  <a href="#" className="d-flex color-item align-items-center">
                    <span className="bg-success color d-inline-block rounded-circle mr-2"></span>{' '}
                    <span className="text-black">Green (2,298)</span>
                  </a>
                  <a href="#" className="d-flex color-item align-items-center">
                    <span className="bg-info color d-inline-block rounded-circle mr-2"></span>{' '}
                    <span className="text-black">Blue (1,075)</span>
                  </a>
                  <a href="#" className="d-flex color-item align-items-center">
                    <span className="bg-primary color d-inline-block rounded-circle mr-2"></span>{' '}
                    <span className="text-black">Purple (1,075)</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img src="images/img.png" className="img" alt="#" />
      )}
    </>
  );
};

export default OneCategory;
