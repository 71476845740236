import { useEffect, useState } from 'react';
import ProductItemList from './ProductItemList';
import { Link } from 'react-router-dom';
const RepresentedProducts = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  // with this we can detect any changes of the sort state in the parent component
  useEffect(() => {}, [props.sort]);

  useEffect(() => {
    setProducts(props.products);
    setLoaded(false);
    setLoaded(true);
  }, [props.pageTitle, props.products]);

  function changePage(n) {
    // Ensure that the new active page is within a valid range
    if (n < 1 || n > 30) {
      return;
    }

    // Calculate the new page based on the active page and the desired change
    let newPage;
    if (n > page + 4) {
      newPage = n;
    } else if (n < page) {
      newPage = n - 4;
    } else {
      newPage = page;
    }

    // Update the state
    setActivePage(n);
    setPage(newPage);
  }

  return (
    <>
      <div className="col-md-12">
        <div className="float-md-right mb-4">
          <h2 className="text-black h5 iranfontbold">{props.pageTitle}</h2>
        </div>
      </div>
      {loaded === true ? (
        <div className="col-md-9 order-2">
          <div className="row mb-5 m-0">
            {products.map((p) => (
              <ProductItemList
                key={p.productId}
                ProductID={p.productId}
                ProductName={p.productName}
                ProductWeight={p.productWeight}
                ProductDescription={p.productShortDescription}
                ProductPrice={p.productPrice}
                discount={p.discount}
                ProductDiscount={p.productDiscount}
                ProductImagePath={p.productImagePath}
              />
            ))}
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="site-block-27">
                <ul>
                  <li>
                    <Link onClick={() => changePage(page - 1)} to="#">
                      &lt;
                    </Link>
                  </li>
                  <li className={page == activePage ? 'active' : ''}>
                    <Link onClick={() => changePage(page)} to="#">
                      {page}
                    </Link>
                  </li>
                  <li className={page + 1 === activePage ? 'active' : ''}>
                    <Link onClick={() => changePage(page + 1)} to="#">
                      {page + 1}
                    </Link>
                  </li>
                  <li className={page + 2 === activePage ? 'active' : ''}>
                    <Link onClick={() => changePage(page + 2)} to="#">
                      {page + 2}
                    </Link>
                  </li>
                  <li className={page + 3 === activePage ? 'active' : ''}>
                    <Link onClick={() => changePage(page + 3)} to="#">
                      {page + 3}
                    </Link>
                  </li>
                  <li className={page + 4 === activePage ? 'active' : ''}>
                    <Link onClick={() => changePage(page + 4)} to="#">
                      {page + 4}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => changePage(page + 5)} to="#">
                      &gt;
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-9 bg-light order-2">
          <div className="row">
            <img src="images/img.png" className="img" alt="#" />
          </div>
        </div>
      )}
    </>
  );
};

export default RepresentedProducts;
