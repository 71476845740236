import { forwardRef } from 'react';
import './Input.css';
import './RangeInput.css';

const RangeInput = forwardRef((props, ref) => {
  return (
    <div className=" container mb-3">
      <div className="row">
        <label
          htmlFor={props.name}
          className="form-label d-flex"
          style={{ float: 'right' }}
        >
          {props.title}
        </label>
      </div>
      <div className="row">
        <div className="col-4">
          <input
            style={{ direction: props.styleDirection }}
            type={props.type}
            className={props.className}
            id={props.name}
            ref={ref}
            name={props.name}
            placeholder={props.placeholder}
            onChange={props.onChange}
            autoComplete={props.autoComplete}
            value={props.persian ? props.valuePersian : props.value}
          />
        </div>
        <div className="col-4">
          <div className="container">
            <input
              type="range"
              value={props.value}
              min={props.min}
              max={props.max}
              step={props.step}
              style={{ direction: 'ltr' }}
              className="mt-3"
              onChange={props.handleRange}
            />
          </div>
        </div>
        <div className="col-4">
          <div className={props.errorDiv}>{props.errorMsg}</div>
        </div>
      </div>
    </div>
  );
});

export default RangeInput;
