import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Alert from './components/Alert';
import Footer from './components/Footer';
import './App.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Navbar from './components/Navbar';
import { ContextProvider } from './Context';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

function App() {
  const [action, setAction] = useState(false);
  const [jwtToken, setJwtToken] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertClassName, setAlertClassName] = useState('d-none');
  const [loaded, setLoaded] = useState(true);
  const [goldUnit, setGoldUnit] = useState(3153400);

  const handleVisibilityChange = () => {
    if (document[hidden]) {
      setAction(false);
    } else {
      setAction(true);
      // here I should load new data
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    // Scroll to the top when the pathname changes
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    setLoaded(true);
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, []);

  return (
    <ContextProvider>
      <div className="container-fluid b-0 p-0">
        {loaded === false ? (
          <div className="row">
            <div className="col-md-12 col-sm-12" style={{ direction: 'rtl' }}>
              <img src="images/img.png" className="img" alt="#" />
            </div>
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-md-12 col-sm-12" style={{ direction: 'rtl' }}>
                <Alert message={alertMessage} className={alertClassName} />
                <Navbar token={jwtToken} setToken={setJwtToken} />
                <Outlet
                  context={{
                    jwtToken,
                    setJwtToken,
                    setAlertClassName,
                    setAlertMessage,
                    goldUnit,
                  }}
                />
                <Footer />
              </div>
            </div>
          </>
        )}
      </div>
    </ContextProvider>
  );
}

export default App;
// when you want to store something in localstorage
// useEffect(() => {
//   if (typeof window !== 'undefined') {
//     setProducts(
//       localStorage.getItem('cartItems')
//         ? JSON.parse(localStorage.getItem('cartItems'))
//         : []
//     );
//     setProduct(products['productName']);
//     setProductQty(products['productQty']);
//   }
// }, []);

// when we want to use local storage
// if (typeof window !== undefined) {
//   localStorage.setItem(
//     'cartItems',

//     JSON.stringify({ productName: 'product11', qty: 12 })
//   );
// }
