import { useEffect, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { ConvertNumberToPersion, ConvertNumberToPersionUnit } from './Utils';
import Input from './form/Input';

const ManageCatalogue = () => {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const requestOptions = {
      method: 'GET',
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/admin/products`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [navigate]);

  return (
    <>
      <div className="col-6 mb-5">
        <h2 style={{ float: 'right', fontFamily: 'iranyek' }}>
          مدیریت محصولات
        </h2>
      </div>
      <div className="col-6 mx-auto iranfontbold">
        <Input
          title={'جستجو'}
          className={'form-control'}
          type={'text'}
          name={'search'}
          value={searchTerm}
          onChange={handleChange}
        />
      </div>
      <hr />
      <div className="col-6 mx-auto">
        <table
          className="table table-striped table-bordered table-hover"
          style={{ direction: 'rtl', textAlign: 'right' }}
        >
          <thead>
            <tr>
              <th className="iranfontbold">شماره</th>
              <th className="iranfontbold">محصول</th>
              <th className="iranfontbold">وزن</th>
              <th className="iranfontbold">قیمت</th>
              <th className="iranfontbold">تخفیف</th>
            </tr>
          </thead>
          <tbody className="iranfontregular">
            {products.map((m) => (
              <tr key={m.productId} href={`/admin/products/${m.productId}`}>
                <td>
                  <Link
                    to={`/admin/products/${m.productId}`}
                    className="text-primary"
                  >
                    {ConvertNumberToPersionUnit(m.productId.toString())}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/admin/products/${m.productId}`}
                    className="text-primary"
                  >
                    {m.productName}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/admin/products/${m.productId}`}
                    className="text-dark"
                  >
                    {ConvertNumberToPersionUnit(m.productWeight)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/admin/products/${m.productId}`}
                    className="text-dark"
                  >
                    {ConvertNumberToPersion(m.productPrice)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={`/admin/products/${m.productId}`}
                    className="text-dark"
                  >
                    {m.discount ? 'دارد' : 'ندارد'}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ManageCatalogue;
