import { useContext, useEffect, useState } from 'react';
import {
  Link,
  useOutletContext,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import './Navbar.css';
import { Context } from '../Context';

function Navbar(props) {
  const [Checkout, setCheckout] = useState(false);
  const [cart, setCart] = useState([]);
  const [path, setPath] = useState('/');
  const { items, setItems } = useContext(Context);
  const location = useLocation();

  let styleNav = 'border-left border-secondary';

  const navigate = useNavigate();

  useEffect(() => {
    const { pathname } = location;
    setPath(pathname);
  }, [location]);

  function AddItem(product) {
    setCart((current) => [...current, product]);
  }

  const logOut = () => {
    // const requestOptions = {
    //   method: 'GET',
    //   credentials: 'include',
    // };

    // fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptions)
    //   .catch((error) => {
    //     console.log('error logging out', error);
    //   })
    //   .finally(() => {
    //     setJwtToken('');
    //     toggleRefresh(false);
    //   });
    props.setToken('');

    navigate('/login');
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12 iranfont">
          <h5 className="mt-3 pr-4" style={{ float: 'right' }}>
            {props.token === '' ? (
              <>
                <Link to="/login">
                  <span className="badge bg-light ml-4">ورود</span>
                </Link>
                <Link to="/login">
                  <span className="badge bg-light">ثبت نام</span>
                </Link>
              </>
            ) : (
              <a href="#!">
                <span className="badge bg-light text-danger" onClick={logOut}>
                  خروج
                </span>
              </a>
            )}
          </h5>
          <nav
            className="navbar navbar-light iranfontregular"
            style={{ float: 'left' }}
          >
            <div className="navbar-text ml-auto d-flex">
              {Checkout != true && (
                <>
                  <div className="dropdown ml-2">
                    <button
                      className="btn btn-light btn-sm dropdown-toggle"
                      id="cartDropdown"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <a>
                        <span>
                          <i className="fa fa-shopping-cart fa-2x"></i>
                          <span
                            className="badge badge-light border border-danger text-danger"
                            style={{
                              position: 'absolute',
                              top: '0px',
                              right: '0px',
                              display: 'block',
                            }}
                          >
                            {items}
                          </span>
                        </span>
                      </a>
                    </button>

                    <div
                      className="dropdown-menu dropdown-menu-left"
                      aria-labelledby="cartDropdown"
                    >
                      {cart.map((item, index) => {
                        <div className="dropdown-item-text text-nowrap text-right">
                          <span className="badge badge-pill badge-warning align-text-top mr-1">
                            {item.qty}
                          </span>
                          {item.product.name}
                          <b>{item.price}</b>
                          <a href="#" className="badge badge-danger text-white">
                            -
                          </a>
                        </div>;
                      })}
                      <Link
                        className="btn btn-sm btn-outline-info text-dark float-right mr-4 iranfont"
                        to="/checkout"
                      >
                        پرداخت
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-2 m-0 p-0">
          <Link to={'/'}>
            <img
              className="border border-light rounded"
              src="/logo512.png"
              style={{
                width: '50px',
                height: '50px',
                float: 'left',
              }}
              alt="/"
            />
          </Link>
        </div>
        <div className="col">
          <div>
            <div className="">
              <nav
                className="navbar navbar-expand-lg border navbar-light rounded"
                style={{
                  float: 'right',
                  padding: '0',
                  marginBottom: '1%',
                }}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse iranfont small"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link d-flex active"
                        // className={
                        //   path === '/'
                        //     ? 'nav-link d-flex active'
                        //     : 'nav-link d-flex'
                        // }
                        to="/"
                      >
                        خانه
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link d-flex active"
                        // className={
                        //   path === '/products'
                        //     ? 'nav-link d-flex active'
                        //     : 'nav-link d-flex active'
                        // }
                        to="/products"
                      >
                        جواهرات
                      </Link>
                    </li>
                    <li className="nav-item dropdown ">
                      <a
                        className="nav-link dropdown-toggle d-flex active"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        جواهرات
                      </a>
                      <div
                        className="dropdown-menu small"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item d-flex small"
                          to={{
                            pathname: `/products`,
                            search: `?category=گردنبند`,
                          }}
                        >
                          گردنبند
                        </Link>
                        <Link
                          className="dropdown-item d-flex small"
                          to={{
                            pathname: `/products`,
                            search: `?category=دستبند`,
                          }}
                        >
                          دستبند
                        </Link>
                        <Link
                          className="dropdown-item d-flex small"
                          to={{
                            pathname: `/products`,
                            search: `?category=انگشتر`,
                          }}
                        >
                          انگشتر
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <Link
                        // className={
                        //   path === '/categories'
                        //     ? 'nav-link d-flex active'
                        //     : 'nav-link d-flex'
                        // }
                        className="nav-link d-flex active"
                        to="/categories"
                      >
                        سفارشات خاص
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link d-flex active" href="#">
                        ارتباط با ما
                      </a>
                    </li>
                    {props.token === '' && (
                      <>
                        <li className="nav-item">
                          <Link
                            to="/admin/products/0"
                            className="nav-link d-flex active"
                            // className={
                            //   path === '/categories'
                            //     ? 'nav-link d-flex active'
                            //     : 'nav-link d-flex'
                            // }
                          >
                            اضافه کردن محصول
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/admin/categories/0"
                            // className={
                            //   path === '/categories'
                            //     ? 'nav-link d-flex active'
                            //     : 'nav-link d-flex'
                            // }
                            className="nav-link d-flex active"
                          >
                            مدیریت دسته بندی
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/manage-catalogue"
                            // className={
                            //   path === '/categories'
                            //     ? 'nav-link d-flex active'
                            //     : 'nav-link d-flex'
                            // }
                            className="nav-link d-flex active"
                          >
                            مدیریت محصولات
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default Navbar;
