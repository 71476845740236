import { Link } from 'react-router-dom';
import './ProductItemList.css';
import './Products.css';
import { ConvertNumberToPersion, ConvertNumberToPersionUnit } from './Utils.js';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductItemList = (props) => {
  return (
    <div className="col-sm-6 col-lg-4 mb-4 iranfont">
      <div className="text-center border">
        <figure className="block-4-image mt-2">
          <Link to={'/products/' + props.ProductID}>
            <LazyLoadImage
              src={props.ProductImagePath}
              alt={props.Alt}
              className="img-box"
              effect="blur"
            />
            {/* <img
              src={props.ProductImagePath}
              alt={props.Alt}
              className="img-box"
            /> */}
          </Link>
        </figure>
        <div className="block-4-text p-4">
          <p>
            <Link
              className="text-dark iranfontbold"
              to={'/products/' + props.ProductID}
            >
              {props.ProductName}
            </Link>
          </p>
          <p className="mb-0 iranfontregular">{props.ProductDescription}</p>
          <p className="text-primary font-weight-bold">
            {props.discount ? (
              <>
                <strike style={{ color: '#999', fontSize: '80%' }}>
                  {ConvertNumberToPersionUnit(props.ProductPrice.toString())}
                </strike>{' '}
                <span>
                  {ConvertNumberToPersion(props.ProductDiscount.toString())}
                </span>
              </>
            ) : (
              <span>
                {ConvertNumberToPersion(props.ProductPrice.toString())}
                <br />
              </span>
            )}
          </p>
          <span>
            <a
              href="#!"
              className="btn btn-light border border-dark rounded"
              style={{ fontSize: '0.8em' }}
            >
              اضافه به سبد خرید
            </a>
            <a
              href={'/products/' + props.ProductID}
              className="btn btn-warning iranfont border border-dark rounded"
              style={{ fontSize: '0.8em' }}
            >
              مشاهده
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProductItemList;
