import './Header.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import carousel CSS

const Header = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1, // Number of items to show at a time on desktop
      slidesToSlide: 1, // Slide one item at a time
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <header>
        <div className="">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                <Carousel
                  responsive={responsive}
                  infinite={true} // Loop the items
                  autoPlay={true}
                  autoPlaySpeed={10000} // Slide every 3 seconds
                  keyBoardControl={true}
                  showDots={false}
                  containerClass="carousel-container"
                  itemClass="carousel-item-padding-40-px"
                  rtl={true}
                  // arrows={false} // Remove navigation arrows
                >
                  <div className="product-item">
                    <img
                      src="/logo.jpg"
                      alt="/logo.jpg"
                      className="product-image"
                    />
                  </div>
                  <div className="product-item">
                    <img
                      src="/images/fancy.jpg"
                      alt="/images/fancy.jpg"
                      className="product-image"
                    />
                  </div>
                </Carousel>
                {/* <img
                  className="border border-light rounded"
                  src="images/logo.jpg"
                  style={{
                    backgroundSize: 'contain',
                    margin: 'auto',
                  }}
                  alt="#"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
